const hamburgerButton = document.querySelector('[data-js-open-menu]')
const body = document.querySelector('body')
const openedClass = 'is--opened'
const expandedClass = 'is--expanded'
const openedNavigationLayerClass = 'has--opened-navigation-layer'

if (hamburgerButton) {
  hamburgerButton.addEventListener('click', function () {
    const expanded = this.classList.contains(expandedClass)
    const controls = document.querySelector('#' + this.getAttribute('aria-controls'))

    if (expanded === false) {
      this.classList.add(expandedClass)
      this.setAttribute('aria-expanded', 'true')
      controls.classList.add(openedClass)
      body.classList.add(openedNavigationLayerClass)

      // close navigation layer on esc key
      document.addEventListener('keydown', (event) => {
        if (event.key === 'Escape') {
          this.classList.remove(expandedClass)
          this.setAttribute('aria-expanded', 'false')
          controls.classList.remove(openedClass)
          body.classList.remove(openedNavigationLayerClass)
        }
      }, false)
    } else {
      this.classList.remove(expandedClass)
      this.setAttribute('aria-expanded', 'false')
      controls.classList.remove(openedClass)
      body.classList.remove(openedNavigationLayerClass)
    }
  }, false)

  document.addEventListener('click', function (event) {
    const controls = document.querySelector('#' + hamburgerButton.getAttribute('aria-controls'))

    if (event.target !== hamburgerButton && event.target.closest('.navigation-layer') !== controls) {
      controls.classList.remove(openedClass)
      hamburgerButton.classList.remove(expandedClass)
      body.classList.remove(openedNavigationLayerClass)
    }
  })
}
